import React from 'react'
import { Trans } from 'react-i18next'
import LoaderButton from '../../../components/inputs/LoaderButton'
import { useDispatch } from 'react-redux'
import { updateDialogVisibility } from '../../../store/ioSlice'

const I18N_KEY = 'InsightGeneration'
const InsightDialogButton: React.FC = () => {
  const dispatch = useDispatch()

  const openInsightGenerationDialog = () => {
    dispatch(updateDialogVisibility({ isOpen: true }))
  }

  return (
    <LoaderButton
      fullWidth
      type="submit"
      color="primary"
      variant="contained"
      loading={false}
      onClick={openInsightGenerationDialog}
    >
      <Trans i18nKey={`${I18N_KEY}.openDialog`}>Generate New Insights</Trans>
    </LoaderButton>
  )
}

export default InsightDialogButton
