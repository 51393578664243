import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'

import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import ExpandMore from '@material-ui/icons/ExpandMore'

import useAppState from 'src/hooks/useAppState'
import useBenchmark from 'src/hooks/useBenchmark'
import { CategoryType } from 'src/utils/golfConstants'
import { playerSelector } from 'src/store/playerSlice'
import Legend from 'src/components/graph/composed/Legend'
import ComposedGraph from 'src/components/graph/composed'
import { SummarySingleItem } from 'src/store/summarySlice'
import { generateCategoryPath } from 'src/modules/categories/routesConfig'
import StrokesProgressLabel from 'src/components/dataDisplay/StrokesProgressLabel'

const I18N_KEY = 'DashboardGraph'

interface Props {
  title: string
  type: CategoryType
  showLegend?: boolean
  isSmallScreen: boolean
  width: string | number
  isCollapsable?: boolean
  height: string | number
  payload: SummarySingleItem
}

interface ExpandStyleProps {
  $expanded: boolean
}

const Container = styled(Paper)<ExpandStyleProps>(
  ({ theme, $expanded }) => css`
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    padding: ${theme.spacing(3, 3, 3, 0)};

    ${theme.breakpoints.down('xs')} {
      padding: ${$expanded
        ? theme.spacing(2, 2, 2, 0)
        : theme.spacing(2, 2, 0, 0)};
    }
  `
)

const HeaderContent = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${theme.breakpoints.up(750)} {
      flex-direction: row;
      justify-content: space-between;
    }
  `
)

const HeaderInfo = styled.div<{ $isCollapsable: boolean }>(
  ({ theme, $isCollapsable }) => css`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    padding-left: ${theme.spacing(4.5)}px;

    ${theme.breakpoints.down('xs')} {
      flex-direction: row;
      align-items: baseline;
      justify-content: space-between;
      margin: ${theme.spacing(0, 0, 1.5)};
      padding-left: ${theme.spacing(2)}px;
      width: ${$isCollapsable ? '90%' : '100%'};
    }
  `
)

const Button = styled(MuiButton)(
  ({ theme }) => css`
    margin-left: auto;
    margin-top: ${theme.spacing(2)}px;
    background-color: ${theme.palette.background.xlight};
  `
)

const ExpandButton = styled(IconButton)<ExpandStyleProps>(
  ({ theme, $expanded }) => css`
    top: 8px;
    right: 4px;
    position: absolute;
    padding: ${theme.spacing(1)}px;

    svg {
      transition: transform 0.3s ease-out;
      fill: ${theme.palette.background.grey};
      transform: ${$expanded ? 'rotate(180deg)' : 'rotate(0deg)'};
    }
  `
)

const StyledCollapse = styled(Collapse)`
  .MuiCollapse-wrapperInner {
    display: flex;
    flex-direction: column;
  }
`

const DashboardGraph: React.FC<Props> = ({
  type,
  title,
  width,
  height,
  payload,
  isSmallScreen,
  showLegend = false,
  isCollapsable = true,
}) => {
  const [isActive, setIsActive] = useState(false)
  const [expanded, setExpanded] = useState(!isCollapsable)

  const { isPlayer } = useAppState()
  const { uuid } = useSelector(playerSelector)
  const { benchmarkId, ...benchmarkInfo } = useBenchmark()

  const history = useHistory()

  const dataExists = payload.sgTotal !== null

  const handleActive = () => {
    setIsActive(true)
  }

  const handleInActive = () => {
    setIsActive(false)
  }

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  const handleDetailsClick = () => {
    if (dataExists) {
      const path = generateCategoryPath(`/${type}`, isPlayer ? undefined : uuid)
      history.push(path)
    }
  }

  return (
    <Container
      elevation={0}
      $expanded={expanded}
      onMouseEnter={handleActive}
      onMouseLeave={handleInActive}
    >
      <HeaderContent>
        <HeaderInfo $isCollapsable={isCollapsable}>
          <Typography>{title}</Typography>
          <StrokesProgressLabel
            dataExists={dataExists}
            sgValue={payload.sgTotal}
            progress={payload.progress}
          />
        </HeaderInfo>
        {showLegend && <Legend />}
        {isCollapsable && isSmallScreen && (
          <ExpandButton
            color="primary"
            $expanded={expanded}
            onClick={toggleExpanded}
          >
            <ExpandMore />
          </ExpandButton>
        )}
      </HeaderContent>
      <StyledCollapse in={isSmallScreen && isCollapsable ? expanded : true}>
        <ComposedGraph
          width={width}
          height={height}
          active={isActive}
          payload={payload}
          id={`sg-${type}`}
          dataExists={dataExists}
          {...benchmarkInfo}
        />
        {dataExists && (
          <Button size="small" color="primary" onClick={handleDetailsClick}>
            <Trans i18nKey={`${I18N_KEY}.detailedView`}>Detailed view</Trans>
          </Button>
        )}
      </StyledCollapse>
    </Container>
  )
}

export default DashboardGraph
