import React from 'react'
import styled, { css } from 'styled-components'
import TableCell from '@material-ui/core/TableCell'
import { breakpoints } from '../../../utils/constants'
import Typography from '@material-ui/core/Typography'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Trans } from 'react-i18next'
import TableBody from '@material-ui/core/TableBody'
import { handleNumberFormat } from '../../../utils/helpers'
import Box from '@material-ui/core/Box'
import { ShotsDistributionItem } from '../../../utils/golfConstants'
import { DashIcon } from '../../../components/graph/common'

interface Props {
  data: ShotsDistributionItem[]
  isCompactSummary: boolean
}

const StyledCell = styled(TableCell)(
  ({ theme }) => css`
    padding: ${theme.spacing(1.5, 2)};
    text-align: center;

    ${theme.breakpoints.down(breakpoints.mobile)} {
      padding: 0;
      text-align: center;
      min-width: unset;
      max-width: 100px;
    }
  `
)

const StyledTitleCell = styled(TableCell)(
  ({ theme }) => css`
    ${theme.breakpoints.down(breakpoints.mobile)} {
      padding: 0;
      min-width: unset;
      max-width: 100px;
      padding-bottom: ${theme.spacing(1)}px;
    }
  `
)

const I18N_KEY = 'ShotDistribution'

const createTableBody = (data: ShotsDistributionItem[]) => {
  return data.map(value => (
    <TableRow key={value.name}>
      <StyledTitleCell>
        <Typography>
          <Trans i18nKey={`${I18N_KEY}.${value.name}`}>{value.name}</Trans>
        </Typography>
      </StyledTitleCell>
      <StyledCell>
        <Typography variant="body2">
          {value.percentage ? (
            handleNumberFormat(value.percentage * 100, false, 1)
          ) : (
            <DashIcon />
          )}
          %
        </Typography>
      </StyledCell>

      <StyledCell>
        <Typography variant="body2">
          {value.averagePerRound ? (
            handleNumberFormat(value.averagePerRound)
          ) : (
            <DashIcon />
          )}
        </Typography>
      </StyledCell>
      <StyledCell>
        <Typography variant="body2">
          <Box
            component="span"
            color={
              value.perShot
                ? value.perShot < 0
                  ? 'error.main'
                  : 'success.main'
                : ''
            }
          >
            {value.perShot ? handleNumberFormat(value.perShot) : <DashIcon />}
          </Box>
        </Typography>
      </StyledCell>
    </TableRow>
  ))
}

const ShotsDistributionTable: React.FC<Props> = ({
  data,
  isCompactSummary,
  ...props
}) => {
  return (
    <TableContainer {...props}>
      <Table aria-label="rounds table">
        <TableHead>
          <TableRow>
            <StyledTitleCell>
              <Typography variant="caption" color="textSecondary">
                <Trans i18nKey={`${I18N_KEY}.category`}>Category</Trans>
              </Typography>
            </StyledTitleCell>
            {!isCompactSummary && (
              <>
                <StyledTitleCell>
                  <Typography variant="caption" color="textSecondary">
                    <Trans i18nKey={`${I18N_KEY}.percentOfTime`}>
                      % of time
                    </Trans>
                  </Typography>
                </StyledTitleCell>
                <StyledTitleCell>
                  <Typography variant="caption" color="textSecondary">
                    <Trans i18nKey={`${I18N_KEY}.averagePerRound`}>
                      Avg # per round
                    </Trans>
                  </Typography>
                </StyledTitleCell>
              </>
            )}
            <StyledTitleCell>
              <Typography variant="caption" color="textSecondary">
                <Trans i18nKey={`${I18N_KEY}.sgPerShot`}>SG per shot</Trans>
              </Typography>
            </StyledTitleCell>
          </TableRow>
        </TableHead>
        <TableBody>{createTableBody(data)}</TableBody>
      </Table>
    </TableContainer>
  )
}

export default ShotsDistributionTable
