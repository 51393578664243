import React from 'react'
import { ShotsDistributionItem } from '../../../utils/golfConstants'
import { Cell, Pie, PieChart } from 'recharts'
import styled, { css } from 'styled-components'
import { breakpoints } from '../../../utils/constants'
import ShotsDistributionTable from './ShotsDistributionTable'
import { Trans } from 'react-i18next'
import Paper from '@material-ui/core/Paper'

const I18N_KEY = 'ShotDistribution'

interface Props {
  data: PieDataFormat[]
  items: ShotsDistributionItem[]
  colors: Map<string, string>
}

export interface PieDataFormat {
  name: string
  value: number
}

interface PieChartDetails {
  cx: number
  cy: number
  midAngle: number
  innerRadius: number
  outerRadius: number
  percent: number
  index: number
}

const Container = styled(Paper)(
  ({ theme }) =>
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: ${theme.spacing(2, 4)};

      ${theme.breakpoints.down(1000)} {
        flex-direction: column;
        justify-content: center;
      }

      ${theme.breakpoints.down('sm')} {
        padding: ${theme.spacing(2, 2)};
      }
    `
)

const PieContainer = styled.div(
  () => css`
    width: 90%;
    display: flex;
    justify-content: center;
    overflow: hidden;

    .recharts-wrapper {
      display: flex;
      justify-content: center;
    }
  `
)

const StyledPieChart = styled(PieChart)(
  ({ theme }) =>
    css`
      .recharts-surface {
        overflow: visible;
      }

      ${theme.breakpoints.down(1000)} {
        margin-left: ${theme.spacing(-10)}px;
      }

      ${theme.breakpoints.down(breakpoints.mobile)} {
        margin-left: ${theme.spacing(2)}px;
        .recharts-surface {
          max-width: 250px;
        }
      }
    `
)

const ComparisonTable = styled(ShotsDistributionTable)(
  ({ theme, isCompactSummary }) => css`
    width: 90%;
    overflow-x: ${isCompactSummary ? 'hidden' : 'auto'};
    margin-left: ${isCompactSummary ? '10em' : 'auto'};

    ${theme.breakpoints.down(1000)} {
      width: 100%;
      margin: auto;
    }

    ${theme.breakpoints.down(breakpoints.mobile)} {
      width: 100%;
    }
  `
)

const ShotsDistribution: React.FC<Props> = ({ data, items, colors }) => {
  const RADIAN = Math.PI / 180
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    index,
  }: PieChartDetails) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.2
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    if (data[index].value > 0) {
      return (
        <text
          x={x}
          y={y}
          fill="#000000"
          textAnchor={x > cx ? 'start' : 'end'}
          dominantBaseline="central"
        >
          <Trans i18nKey={`${I18N_KEY}.${data[index].name}`}>
            {data[index].name}
          </Trans>
        </text>
      )
    }
  }

  return (
    <Container elevation={0}>
      <PieContainer>
        <StyledPieChart height={350} width={350}>
          <Pie
            dataKey="value"
            label={renderCustomizedLabel}
            labelLine={false}
            data={data}
            cx="50%"
            cy="50%"
            outerRadius={120}
            innerRadius={50}
            fill="#8884d8"
          >
            {data.map((entry, index) => (
              <Cell
                style={{ outline: 'none' }}
                key={`cell-${index}`}
                fill={colors.get(entry.name)}
                stroke="#fff"
                strokeWidth={4}
              />
            ))}
          </Pie>
        </StyledPieChart>
      </PieContainer>
      <ComparisonTable data={items} isCompactSummary={false} />
    </Container>
  )
}

export default ShotsDistribution
