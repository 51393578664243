import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  authRequestFail,
  getPayloadFromResponse,
  getSinglePayloadFromResponse,
  getUserInfo,
  isForbiddenOrUnauthorised,
} from 'src/utils/helpers'
import { api } from 'src/utils/api'
import { AppThunk } from 'src/store'
import {
  activities as defaultActivities,
  CategorisedActivities,
} from 'src/utils/practiceActivities'
import { RootState } from 'src/store/rootReducer'
import { MembershipLevel, PAGINATION_LIMIT } from 'src/utils/constants'
import { Activity, Practice } from 'src/utils/golfConstants'
import {
  hasActiveSubscription,
  userIsTrialingSelector,
} from 'src/store/subscriptionSlice'

interface InitialState {
  isOpen: boolean
  totalCount: number
  totalSubmitted: number
  practices: Practice[]
  practicesLoaded: boolean
  status: PracticeStatus
  selectedPractice: Practice | null
  isSelectedPracticeLoading: boolean
}

export enum PracticeStatus {
  Valid = 'valid',
  Error = 'error',
  Loading = 'loading',
  CreatingNew = 'creating_new',
  NoPracticesError = 'no_practices_error',
}

export type ActivityPayload = Pick<
  Activity,
  'input' | 'activityId' | 'activityName'
>

interface DialogVisibility {
  isOpen: boolean
  uuid?: string
}

const initialState: InitialState = {
  practices: [],
  practicesLoaded: false,
  isOpen: false,
  totalCount: 0,
  totalSubmitted: 0,
  selectedPractice: null,
  status: PracticeStatus.Valid,
  isSelectedPracticeLoading: false,
}

const { actions, reducer } = createSlice({
  name: 'practice',
  initialState,
  reducers: {
    updateStatus: (
      state,
      action: PayloadAction<Pick<InitialState, 'status'>>
    ) => {
      const { status } = action.payload

      state.status = status

      if (
        status === PracticeStatus.Error ||
        status === PracticeStatus.NoPracticesError
      ) {
        state.practices = []
      }
    },
    updateLoaded: (state, action: PayloadAction<{ loaded: boolean }>) => {
      state.practicesLoaded = action.payload.loaded
    },
    updateDialogVisibility: (
      state,
      action: PayloadAction<DialogVisibility>
    ) => {
      const { isOpen, uuid } = action.payload

      const selectedPractice = state.practices?.find(item => item.uuid === uuid)

      state.selectedPractice = selectedPractice || null
      state.isOpen = isOpen
    },
    setSelectedPracticeByUuid: (
      state,
      action: PayloadAction<{ uuid: string }>
    ) => {
      const selectedPractice = state.practices?.find(
        item => item.uuid === action.payload.uuid
      )

      state.selectedPractice = selectedPractice || null
    },
    setSelectedPractice: (
      state,
      action: PayloadAction<{ practice: Practice }>
    ) => {
      state.selectedPractice = action.payload.practice
    },
    updateSelectedPracticeLoading: (
      state,
      action: PayloadAction<{ isLoading: boolean }>
    ) => {
      const { isLoading } = action.payload

      state.isSelectedPracticeLoading = isLoading
    },
    updatePracticeList: (
      state,
      action: PayloadAction<
        Pick<InitialState, 'practices' | 'totalCount' | 'totalSubmitted'>
      >
    ) => {
      const { practices, totalCount, totalSubmitted } = action.payload

      state.practices = practices
      state.totalCount = totalCount
      state.totalSubmitted = totalSubmitted
      state.status = PracticeStatus.Valid
    },
    updatePractice: (state, action: PayloadAction<{ practice: Practice }>) => {
      const { practice } = action.payload

      const updatedPractices = state.practices.slice()
      const practiceIndex = updatedPractices.findIndex(
        item => item.uuid === practice.uuid
      )

      if (practiceIndex < 0) {
        updatedPractices.push(practice)
        state.totalCount = state.totalCount + 1
      } else {
        updatedPractices[practiceIndex] = practice
      }

      state.selectedPractice = practice
      state.practices = updatedPractices
      state.isSelectedPracticeLoading = false
    },
  },
})

export default reducer
export const {
  updateStatus,
  updateLoaded,
  updatePractice,
  updatePracticeList,
  setSelectedPractice,
  updateDialogVisibility,
  setSelectedPracticeByUuid,
  updateSelectedPracticeLoading,
} = actions

// Selectors
export const practiceSelector = (state: RootState) => state.practice

export const practiceStatusSelector = (state: RootState) =>
  state.practice.status

export const practicesLoadedSelector = (state: RootState) =>
  state.practice.practicesLoaded

export const practiceDialogOpen = (state: RootState) => state.practice.isOpen

export const practicesCountSelector = (state: RootState) =>
  state.practice.totalCount

export const practicesSubmittedCountSelector = (state: RootState) =>
  state.practice.totalSubmitted

export const selectedPracticeSelector = (state: RootState) =>
  state.practice.selectedPractice

export const selectedPracticeLoadingSelector = createSelector(
  practiceSelector,
  round => round.isSelectedPracticeLoading
)

export const practicesSelector = (state: RootState) => state.practice.practices

export const practiceDataSelector = createSelector(
  selectedPracticeSelector,
  practice => {
    const selectedActivities: CategorisedActivities = {}

    if (practice) {
      practice.activities.forEach(activity => {
        selectedActivities[activity.category]
          ? selectedActivities[activity.category].push(activity)
          : (selectedActivities[activity.category] = [activity])
      })
    }

    const activities = practice ? selectedActivities : defaultActivities

    return { activities, practice }
  }
)

// Action Creators
export const getPractices =
  (start: number = 0): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()
    const { isPlayer, playerUuid } = getUserInfo(state)
    const isPlayerPremium = hasActiveSubscription(MembershipLevel.Basic)(state)
    const isPlayerTrialing = userIsTrialingSelector(state)

    // Only get practices for Premium & Trialing users
    // because the 403 response causes logout
    if (isPlayerPremium || isPlayerTrialing) {
      dispatch(updateStatus({ status: PracticeStatus.Loading }))

      const endpoint = isPlayer
        ? 'practice'
        : `overview/player/${playerUuid}/practice`

      try {
        const response = await api.get(endpoint, {
          params: { count: true, start, limit: PAGINATION_LIMIT },
        })
        const practices = getPayloadFromResponse(response)
        const totalCount = response.data?.count || 0

        if (totalCount === 0) {
          throw new Error(PracticeStatus.NoPracticesError)
        }

        const totalSubmitted =
          practices.map((practice: Practice) => practice.hasSubmitted).length ||
          0

        dispatch(updatePracticeList({ practices, totalCount, totalSubmitted }))
        dispatch(updateLoaded({ loaded: true }))
      } catch (error: any) {
        if (isForbiddenOrUnauthorised(error)) {
          authRequestFail(dispatch)
        } else if (error.message === PracticeStatus.NoPracticesError) {
          dispatch(updateStatus({ status: PracticeStatus.NoPracticesError }))
        } else {
          dispatch(updateStatus({ status: PracticeStatus.Error }))
        }
      }
    }
  }

export const getSinglePractice =
  (uuid: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(updateSelectedPracticeLoading({ isLoading: true }))
    const { isPlayer, playerUuid } = getUserInfo(getState())
    const endpoint = isPlayer
      ? `practice/${uuid}`
      : `overview/player/${playerUuid}/practice/${uuid}`

    try {
      const response = await api.get(endpoint)
      const practice = getSinglePayloadFromResponse(response)

      dispatch(updatePractice({ practice }))
    } catch (error: any) {
      dispatch(updateSelectedPracticeLoading({ isLoading: false }))
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      } else {
        throw new Error()
      }
    }
  }

export const createPractice = (): AppThunk => async (dispatch, getState) => {
  dispatch(updateStatus({ status: PracticeStatus.CreatingNew }))

  const state = getState()
  // If the user does not have subscription
  // we don't request the practices
  // because the 403 response causes logout
  if (!hasActiveSubscription(MembershipLevel.Basic)(state)) {
    return
  }

  const { isPlayer, playerUuid } = getUserInfo(state)
  const endpoint = isPlayer
    ? 'practice'
    : `overview/player/${playerUuid}/practice`

  try {
    const response = await api.post(endpoint)
    const practice = getSinglePayloadFromResponse(response)

    dispatch(setSelectedPractice({ practice }))
    dispatch(updateStatus({ status: PracticeStatus.Valid }))
  } catch (error: any) {
    dispatch(updateStatus({ status: PracticeStatus.Error }))

    if (isForbiddenOrUnauthorised(error)) {
      authRequestFail(dispatch)
    } else {
      throw new Error()
    }
  }
}

export const saveExistingPractice =
  (uuid: string, date: Date, activities: ActivityPayload[]): AppThunk =>
  async (dispatch, getState) => {
    const { isPlayer, playerUuid } = getUserInfo(getState())
    const endpoint = isPlayer
      ? `practice/${uuid}`
      : `overview/player/${playerUuid}/practice/${uuid}`

    try {
      const response = await api.put(endpoint, { date, activities })
      const practice = getSinglePayloadFromResponse(response)

      dispatch(updatePractice({ practice }))
      dispatch(getPractices())
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      } else {
        throw new Error()
      }
    }
  }

export const deletePractice =
  (uuid: string): AppThunk =>
  async (dispatch, getState) => {
    const { isPlayer, playerUuid } = getUserInfo(getState())
    const endpoint = isPlayer
      ? `practice/${uuid}`
      : `overview/player/${playerUuid}/practice/${uuid}`

    try {
      await api.delete(endpoint)
      await dispatch(getPractices())
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      throw new Error()
    }
  }
