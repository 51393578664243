import React, { useMemo, useContext, useEffect } from 'react'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Paper, Container, Typography } from '@material-ui/core'

import {
  SummaryStatus,
  SummarySingleItem,
  summaryStatusSelector,
  summarySubCategoryData,
  summaryComposedDataSelector,
  metricPerformanceIndicatorsSelector,
  summaryRoundsSelector,
  summaryShotsDistributionByMetric,
  summaryShotsDistribution,
  getSummary,
} from 'src/store/summarySlice'
import {
  MetricId,
  CategoryType,
  CategoryMetricIds,
  ShotsDistributionItem,
} from 'src/utils/golfConstants'
import {
  hasActiveSubscription,
  userIsTrialingSelector,
} from 'src/store/subscriptionSlice'
import { getRoundTitle } from 'src/utils/helpers'
import { fadeVariants } from 'src/utils/animations'
import ForecastGraph from 'src/components/graph/forecast'
import {
  totalRoundsSelector,
  updateTotalRounds,
  lastUpdatedRoundSelector,
  updateLastUpdatedRound,
} from 'src/store/roundSlice'
import { TranslationContext } from 'src/utils/TranslationContext'
import UpgradePanel from 'src/components/membership/UpgradePanel'
import CategoryHeader from 'src/modules/categories/CategoryHeader'
import SubcategoryGrid from 'src/modules/categories/SubcategoryGrid'
import { DateFormats, DISPLAY_PREDICTION } from 'src/utils/constants'
import ReferencedTitle from 'src/components/dataDisplay/ReferencedTitle'
import CategoryRoundsTable from 'src/modules/categories/CategoryRoundsTable'
import PerformanceIndicatorTable from 'src/components/dataDisplay/PerformanceIndicatorTable'
import { PlanType } from 'src/utils/subscriptionConstants'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { HeadingContainer } from '../../components/subscriptionFlow/payment/common'
import ShotsDistribution, {
  PieDataFormat,
} from '../player-dash/shotsDistribution'
import { forecastDataSelector } from 'src/store/forecastSlice'
import useAppState from 'src/hooks/useAppState'
import {
  fetchLastRoundUpdated,
  fetchRoundCount,
} from 'src/service/roundService'

const I18N_KEY = 'Category'

interface Props {
  metricId: MetricId
  category: CategoryType
  isSubcategory?: boolean
}

const CategoryContainer = styled(motion.div)(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    min-height: 100%;
    position: absolute;
    flex-direction: column;
    background-color: ${theme.palette.background.xlight};
  `
)

const Content = styled(Container)(
  ({ theme }) => css`
    padding-top: ${theme.spacing(8)}px;
    padding-bottom: ${theme.spacing(8)}px;

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(8, 0)};
    }
  `
)

const PredictionCard = styled(Paper)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: ${theme.spacing(16, 4)};
  `
)

const StyledReferencedTitle = styled(ReferencedTitle)(
  ({ theme }) => css`
    padding: ${theme.spacing(0, 0, 2)};

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(0, 2, 2)};
    }
  `
)

const Title = styled(Typography)(
  ({ theme }) => css`
    padding: ${theme.spacing(0, 0, 2)};

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(0, 2, 2)};
    }
  `
)

const ErrorText = styled(Typography)`
  max-width: 600px;
`

const Category: React.FC<Props> = ({
  category,
  metricId,
  isSubcategory = true,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { format } = useContext(TranslationContext)!

  const { isPlayer, playerUuid } = useAppState()
  const totalRounds = useSelector(totalRoundsSelector)
  const lastUpdatedRound = useSelector(lastUpdatedRoundSelector)
  const isPlayerPremium = useSelector(hasActiveSubscription())
  const isPlayerTrialing = useSelector(userIsTrialingSelector)
  const { forecast, benchmarkInfo } = useSelector(forecastDataSelector)

  const showPremiumFeature = isPlayerPremium || isPlayerTrialing
  const { shotsDistributionFlag } = useFlags()

  const showSubcategoryGrid = category !== CategoryType.All && !isSubcategory

  const summaryStatus = useSelector(summaryStatusSelector)

  const isValid = summaryStatus === SummaryStatus.Valid
  const isLoading = summaryStatus === SummaryStatus.Loading

  const categoryData = useSelector(summaryComposedDataSelector)[category]
  const subCategoryData = useSelector(summarySubCategoryData(category))!

  const data = isSubcategory
    ? (subCategoryData[metricId as MetricId] as SummarySingleItem)
    : categoryData

  const rounds = useSelector(summaryRoundsSelector)

  useEffect(() => {
    const fetchSummaryWithCheck = async () => {
      // Only fetch the summary if there's been a change to round data
      let roundDataChanged = false

      const count = await fetchRoundCount(isPlayer, playerUuid)
      if (count !== totalRounds) {
        roundDataChanged = true
        dispatch(updateTotalRounds({ count }))
      }

      const latestRound = await fetchLastRoundUpdated(isPlayer, playerUuid)
      if (latestRound?.updatedAt !== lastUpdatedRound?.updatedAt) {
        roundDataChanged = true
        dispatch(updateLastUpdatedRound({ round: latestRound }))
      }

      if (roundDataChanged) {
        dispatch(getSummary())
      }
    }

    fetchSummaryWithCheck()
  }, [dispatch, totalRounds, lastUpdatedRound, isPlayer, playerUuid])

  const tableData = useMemo(() => {
    return rounds.map(round => {
      const roundTitle = getRoundTitle(round)
      const date = format(new Date(round.datePlayed), DateFormats.TableRow)
      const categoryStrokesGained =
        category === CategoryType.All
          ? round.summary?.strokesGained.total?.overall
          : round.summary?.strokesGained[category]?.overall

      const strokesGained = isSubcategory
        ? round.summary?.categories.find(
            category => category.metricId === metricId
          )?.strokesGained
        : categoryStrokesGained

      return {
        date,
        roundTitle,
        strokesGained,
        uuid: round.uuid,
        type: round.roundType,
      }
    })
  }, [rounds, format, category, isSubcategory, metricId])

  const performanceIndicators = useSelector(
    metricPerformanceIndicatorsSelector(metricId, isSubcategory)
  )

  const shotsDistributionBreakdownData = useSelector(
    summaryShotsDistributionByMetric(metricId)
  )

  const shotsDistribution = useSelector(summaryShotsDistribution)

  const getShotsDistributionItemsKeyPerMetric = (
    metricId: MetricId
  ): string[] => {
    switch (metricId) {
      case MetricId.Drives:
        return [
          'par4LayupRate',
          'par4DriverRate',
          'par5LayupRate',
          'par5DriverRate',
        ]
      case MetricId.DrivesPar4:
        return ['par4LayupRate', 'par4DriverRate']
      case MetricId.DrivesPar5:
        return ['par5LayupRate', 'par5DriverRate']
      case MetricId.Approach:
        return ['from100to149', 'from150to199', 'from200to249', 'over250']
      case MetricId.Approach101To150:
        // eslint-disable-next-line prettier/prettier
        return ['fairway100to124','rough100to124','deepRough100to124','fairway125to149','rough125to149','deepRough125to149']
      case MetricId.Approach151To200:
        // eslint-disable-next-line prettier/prettier
        return ['fairway150to174','rough150to174','deepRough150to174','fairway175to199','rough175to199','deepRough175to199']
      case MetricId.Approach201To250:
        // eslint-disable-next-line prettier/prettier
        return ['fairway200to224', 'rough200to224', 'deepRough200to224', 'fairway225to249', 'rough225to249', 'deepRough225to249']
      case MetricId.ApproachOver250:
        // eslint-disable-next-line prettier/prettier
        return ['fairway250to274', 'rough250to274', 'deepRough250to274', 'fairwayOver275', 'roughOver275', 'deepRoughOver275']
      case MetricId.Short:
        return ['lessThan20', 'from20to59', 'from60to99', 'sand']
      case MetricId.ShortUnder20:
        // eslint-disable-next-line prettier/prettier
        return ['fairway0to9', 'rough0to9', 'deepRough0to9', 'fairway10to19', 'rough10to19', 'deepRough10to19']
      case MetricId.Short21To60:
        // eslint-disable-next-line prettier/prettier
        return ['fairway20to39','rough20to39','deepRough20to39','fairway40to59','rough40to59','deepRough40to59']
      case MetricId.Short61To100:
        // eslint-disable-next-line prettier/prettier
        return ['fairway60to79','rough60to79','deepRough60to79','fairway80to99','rough80to99','deepRough80to99']
      case MetricId.ShortBunkers:
        // eslint-disable-next-line prettier/prettier
        return ['sand0to29','sand30to59','sand60to99']
      case MetricId.Putt:
        return ['lessThan7', 'from7To20', 'over21']
      case MetricId.PuttUnder6:
        // eslint-disable-next-line prettier/prettier
        return ['putting1','putting2','putting3','putting4','putting5','putting6']
      case MetricId.Putt7To21:
        // eslint-disable-next-line prettier/prettier
        return ['putting5to10','putting10to15','putting15to20','putting20to25']
      case MetricId.PuttOver21:
        // eslint-disable-next-line prettier/prettier
        return ['putting25to30','putting30to35','putting35to40','putting40to45','putting45to50','puttingOver50']
    }
    return []
  }

  const getShotsDistributionChartData = (): PieDataFormat[] => {
    const elements: PieDataFormat[] = []
    const keysToBeDisplayed = getShotsDistributionItemsKeyPerMetric(metricId)
    if (metricId === MetricId.All) {
      if (shotsDistribution?.aggregate) {
        for (const [key, value] of Object.entries(
          shotsDistribution.aggregate
        )) {
          if (key !== 'totalStrokes') {
            elements.push({
              name: key,
              value: value.percentage,
            })
          }
        }
      }
    } else {
      if (shotsDistributionBreakdownData) {
        for (const [key, value] of Object.entries(
          shotsDistributionBreakdownData
        )) {
          if (!keysToBeDisplayed.includes(key)) {
            continue
          }
          if (value.percentage) {
            elements.push({
              name: key,
              value: value.percentage,
            })
          } else {
            elements.push({
              name: key,
              value: value,
            })
          }
        }
      }
    }
    return elements
  }

  const getShotsDistributionItems = (): ShotsDistributionItem[] => {
    const items: ShotsDistributionItem[] = []
    const keysToBeDisplayed = getShotsDistributionItemsKeyPerMetric(metricId)
    if (metricId === MetricId.All) {
      if (shotsDistribution?.aggregate) {
        for (const [key, value] of Object.entries(
          shotsDistribution.aggregate
        )) {
          if (key !== 'totalStrokes') {
            items.push({
              name: key,
              ...value,
            })
          }
        }
      }
    } else {
      if (shotsDistributionBreakdownData) {
        for (const [key, value] of Object.entries(
          shotsDistributionBreakdownData
        )) {
          if (!keysToBeDisplayed.includes(key)) {
            continue
          }
          if (key !== 'metricId') {
            if (value.percentage || value.percentage === 0) {
              items.push({
                name: key,
                ...value,
              })
            } else {
              items.push({
                name: key,
                percentage: value,
                averagePerRound: null,
                perShot: null,
              })
            }
          }
        }
      }
    }
    return items
  }

  const getShotsDistributionColorScheme = (): Map<string, string> => {
    const colorsArray = [
      '#DDE5F8',
      '#C4D5FA',
      '#ACC5FC',
      '#94B5FF',
      '#7598FF',
      '#5777FF',
      '#3853FF',
      '#1A2BFF',
      '#0004FA',
      '#0900DB',
      '#1300BD',
      '#19009E',
    ]
    const keysToBeDisplayed = getShotsDistributionItemsKeyPerMetric(metricId)
    const colors: Map<string, string> = new Map()
    let index = 0
    if (metricId === MetricId.All) {
      if (shotsDistribution?.aggregate) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const [key, value] of Object.entries(
          shotsDistribution.aggregate
        )) {
          if (key !== 'totalStrokes') {
            colors.set(key, colorsArray[index])
            index = index + 1
          }
        }
      }
    } else {
      if (shotsDistributionBreakdownData) {
        for (const [key] of Object.entries(shotsDistributionBreakdownData)) {
          if (!keysToBeDisplayed.includes(key)) {
            continue
          }
          colors.set(key, colorsArray[index])
          index = index + 1
        }
      }
    }
    return colors
  }

  return (
    <CategoryContainer
      exit="exit"
      initial="enter"
      animate="visible"
      variants={fadeVariants}
      transition={{ duration: 0.4 }}
    >
      <CategoryHeader
        data={data}
        isValid={isValid}
        category={category}
        metricId={metricId}
        isLoading={isLoading}
        summaryStatus={summaryStatus}
      />
      {isValid && (
        <Content>
          {shotsDistributionFlag ? (
            <Box>
              <HeadingContainer>
                <Box>
                  <ReferencedTitle
                    title={t(
                      `${I18N_KEY}.shotDistribution`,
                      'Shot Distribution'
                    )}
                  />
                </Box>
              </HeadingContainer>
              <ShotsDistribution
                data={getShotsDistributionChartData()}
                items={getShotsDistributionItems()}
                colors={getShotsDistributionColorScheme()}
              />
            </Box>
          ) : (
            ''
          )}
          {showSubcategoryGrid && (
            <Box mt={8}>
              <StyledReferencedTitle
                title={t(`${I18N_KEY}.subCategoriesTitle`, 'Sub Categories')}
              />
              <SubcategoryGrid subCategoryData={subCategoryData} />
            </Box>
          )}
          {DISPLAY_PREDICTION && showPremiumFeature && !isSubcategory && (
            <>
              <Box mt={6} mb={8}>
                <StyledReferencedTitle
                  title={t(`${I18N_KEY}.prediction`, 'Prediction Model')}
                />
                {totalRounds >= 20 ? (
                  <ForecastGraph
                    showFilter={false}
                    forecast={forecast}
                    benchmarkInfo={benchmarkInfo}
                    defaultMetric={metricId as unknown as CategoryMetricIds}
                  />
                ) : (
                  <PredictionCard elevation={0}>
                    <ErrorText color="textSecondary" align="center">
                      <Trans i18nKey={`${I18N_KEY}.predictionError`}>
                        Prediction Model displays your potential improvement
                        trajectory. This is calculated using Circles advanced
                        Artificial Intelligence. After you have entered 20
                        rounds, we will automatically display your Strokes
                        Gained potential looking forward 12 weeks.
                      </Trans>
                    </ErrorText>
                  </PredictionCard>
                )}
              </Box>
            </>
          )}
          <Box mt={8}>
            <StyledReferencedTitle
              title={t(`${I18N_KEY}.statistics`, 'Statistics')}
            />
            {showPremiumFeature || category === CategoryType.All ? (
              <PerformanceIndicatorTable
                isOpen
                performanceIndicators={performanceIndicators}
              />
            ) : (
              <UpgradePanel planType={PlanType.Train}>
                <Trans i18nKey={`${I18N_KEY}.upgradeDescription`}>
                  Performance indicators provide an even more in-depth set of
                  skill benchmarks that relate to this category
                </Trans>
              </UpgradePanel>
            )}
          </Box>
          <Box mt={8} mb={10}>
            <Title variant="h3">
              <Trans i18nKey={`${I18N_KEY}.roundsTitle`}>Rounds</Trans>
            </Title>
            <CategoryRoundsTable rounds={tableData} />
          </Box>
        </Content>
      )}
    </CategoryContainer>
  )
}

export default Category
