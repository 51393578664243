import React, { useMemo } from 'react'
import {
  Area,
  XAxis,
  YAxis,
  Scatter,
  Tooltip,
  TooltipProps,
  CartesianGrid,
  ComposedChart,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts'
import styled, { css, StyledProps } from 'styled-components'

import { useTheme } from '@material-ui/core'

import {
  PositiveGradient,
  NegativeGradient,
} from 'src/components/graph/Gradient'
import { SummarySingleItem } from 'src/store/summarySlice'
import { getXAxisTicks } from 'src/components/graph/utils'
import TooltipContent from 'src/components/graph/composed/TooltipContent'
import { renderScatterDot } from 'src/components/graph/composed/ScatterDot'
import { RenderXAxisTick, RenderYAxisTick } from 'src/components/graph/Axis'

import { ReactComponent as FadingDotIllustration } from 'src/assets/images/fadingDotIllustration.svg'

interface ComposedGraphProps {
  id: string
  active?: boolean
  dataExists: boolean
  showXAxis?: boolean
  benchmarkName: string
  referencePoint: string
  width: string | number
  height: string | number
  payload: SummarySingleItem
}

interface PlaceholderProps {
  height: string | number
}

const NoData = styled(({ height, ...props }) => <div {...props} />)(
  ({ height }: StyledProps<PlaceholderProps>) => css`
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    min-height: ${typeof height === 'string' ? height : `${height}px`};
  `
)

const Illustration = styled(FadingDotIllustration)(
  ({ theme }) => css`
    margin: ${theme.spacing(3, 0, 0)};

    g {
      fill: ${theme.palette.background.grey};
    }
  `
)

const ComposedGraph: React.FC<ComposedGraphProps> = ({
  id,
  width,
  height,
  payload,
  dataExists,
  benchmarkName,
  active = true,
  referencePoint,
  showXAxis = true,
  ...props
}) => {
  const theme = useTheme()

  const xTicks = useMemo(
    () =>
      getXAxisTicks(payload?.data.map(({ datePlayed }) => datePlayed) || []),
    [payload?.data]
  )

  return dataExists ? (
    <ResponsiveContainer width={width} height={height} {...props}>
      <ComposedChart data={payload.data}>
        <defs>
          <PositiveGradient id={id} color={theme.palette.primary.light} />
          <NegativeGradient id={id} color={theme.palette.primary.light} />
        </defs>
        {active && (
          <CartesianGrid
            vertical={false}
            stroke={theme.palette.background.light}
          />
        )}
        <YAxis
          type="number"
          tickLine={false}
          axisLine={false}
          tick={props => <RenderYAxisTick {...props} />}
        />
        {showXAxis && (
          <XAxis
            type="number"
            tickLine={false}
            axisLine={false}
            dataKey="datePlayed"
            ticks={xTicks}
            tickFormatter={() => 'XXX 99'}
            tick={props => <RenderXAxisTick {...props} />}
            domain={['dataMin', 'dataMax']}
          />
        )}
        <ReferenceLine
          strokeWidth={2}
          y={payload.benchmark}
          ifOverflow="extendDomain"
          stroke={theme.palette.warning.main}
        />
        <ReferenceLine
          y={0}
          strokeWidth={2}
          strokeDasharray="4"
          ifOverflow="extendDomain"
          stroke={theme.palette.warning.main}
        />
        <Tooltip
          cursor={{
            strokeWidth: 2,
            stroke: theme.palette.background.grey,
          }}
          wrapperStyle={{ top: 175, zIndex: 10 }}
          content={(props: TooltipProps<number, string>) => (
            <TooltipContent
              {...props}
              benchmarkName={benchmarkName}
              referencePoint={referencePoint}
            />
          )}
        />
        <Area
          dot={false}
          type="monotone"
          strokeWidth={2}
          fillOpacity={1}
          dataKey="rollingAverage"
          activeDot={false}
          animationDuration={1000}
          stroke={theme.palette.primary.light}
          fill={
            payload.sgTotal && payload.sgTotal > 0
              ? `url(#positive-average-${id})`
              : `url(#negative-average-${id})`
          }
        />
        <Scatter
          dataKey="value"
          shape={renderScatterDot}
          animationDuration={1000}
          fill={theme.palette.primary.light}
          stroke={theme.palette.primary.light}
        />
      </ComposedChart>
    </ResponsiveContainer>
  ) : (
    <NoData height={150}>
      <Illustration />
    </NoData>
  )
}

export default ComposedGraph
