import React from 'react'
import styled, { css, StyledProps } from 'styled-components'
import { Dot, DotProps, YAxisProps, ScatterProps } from 'recharts'

import { SummaryGraphPoint } from 'src/store/summarySlice'

type Props = ScatterProps & DotProps & ExtraProps

interface ExtraProps {
  yAxis: YAxisProps
  payload: SummaryGraphPoint
}

interface DotStyleProps {
  fill: string
}

const ScatterDot = styled(({ fill, ...props }) => <Dot {...props} />)(
  ({ fill }: StyledProps<DotStyleProps>) => css`
    fill: ${fill};
    stroke: ${fill};
  `
)

export const renderScatterDot = ({ cx, cy, fill, yAxis, payload }: Props) => {
  const value = payload.value
  // 0 is a valid value
  const valueIsNull = value === null

  const yValue = valueIsNull ? (yAxis.height || 0) + 10 : cy
  const fillValue = valueIsNull ? '#E7E8E9' : fill || 'black'

  return <ScatterDot cx={cx} cy={yValue} r={4} fill={fillValue} />
}
