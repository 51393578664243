import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  extractIOPotential,
  extractRadarData,
  extractScoreInfo,
} from 'src/store/utils'
import {
  authRequestFail,
  getRoundTitle,
  getSinglePayloadFromResponse,
  getStrokesForOpportunity,
  getUserInfo,
  isForbiddenOrUnauthorised,
} from 'src/utils/helpers'
import {
  CategoryRoutes,
  FeatureGateErrors,
  WhereFilter,
  SummaryErrors,
  TimeFilter,
  TypeFilter,
  UserType,
} from 'src/utils/constants'
import {
  CategoryType,
  FocusAreaIO,
  ForecastItem,
  IO,
  MetricId,
  PerformanceIndicator,
  RootCategory,
  RootStrokesGained,
  Round,
  ShotsDistribution,
} from 'src/utils/golfConstants'
import { api } from 'src/utils/api'
import {
  hasActiveSubscription,
  userIsTrialingSelector,
} from 'src/store/subscriptionSlice'
import { AppThunk } from 'src/store'
import { RootState } from 'src/store/rootReducer'
import { userSelector } from 'src/store/userSlice'
import { playerSelector } from 'src/store/playerSlice'
import { generateCategoryPath } from 'src/modules/categories/routesConfig'

interface InitialState {
  filters: Filters
  savedMetrics: IO[]
  IOList: FocusAreaIO[]
  status: SummaryStatus
  suggestedMetrics: IO[]
  rounds: SummaryRound[]
  forecasts: ForecastItem[]
  focusAreas: FocusAreaIO[]
  categories: RootCategory[]
  progressDonut: Progress | null
  strokesGained: RootStrokesGained | null
  benchmarkDetails: BenchmarkDetails | null
  performanceIndicators: PerformanceIndicator[]
  shotsDistribution: ShotsDistribution | null
}

export interface Filters {
  timeFilter: TimeFilter
  typeFilter: TypeFilter
  whereFilter: WhereFilter
  customTimeRange: string | null
}

export interface BenchmarkDetails {
  below: BenchmarkValue
  current: BenchmarkValue
}

export interface BenchmarkValue {
  id: string
  value: number
}

export interface Progress {
  toBenchmark: number
  progressValue: number
}

interface SummaryParams {
  [key: string]: any
}

export interface Strength {
  metricId: string
  metricName: string
  path: string
  datePlayed: number
  difference: number
  benchmark: number
  average: number
  rollingAverage: number
  roundTitle: string
}

export enum SummaryStatus {
  UnInitialised = 'uninitialised',
  Valid = 'valid',
  Error = 'error',
  Loading = 'loading',
  NoRoundsError = 'no_rounds_error',
  NoFilterRounds = 'no_filter_rounds',
}

export type SummaryRound = Omit<Round, 'holes'>

type StatusPayload = Pick<InitialState, 'status'>

type SummaryPayload = Omit<InitialState, 'status'>

type IOPayload = Pick<
  InitialState,
  'IOList' | 'savedMetrics' | 'suggestedMetrics'
>

export type SubcategoryData = {
  [key in MetricId]?: SummarySingleItem
}

export interface SummarySingleItem {
  benchmark: number
  data: SummaryGraphPoint[]
  progress: number | undefined
  sgTotal: null | number | undefined
}

export interface OpportunityItem extends SummarySingleItem {
  path: string
  metricId: MetricId
}

export interface SummaryGraphPoint {
  uuid: string
  average: number
  benchmark: number
  roundTitle: string
  datePlayed?: number
  value: number | null
  rollingAverage?: number | null
}

const initialState: InitialState = {
  IOList: [],
  rounds: [],
  forecasts: [],
  categories: [],
  focusAreas: [],
  savedMetrics: [],
  progressDonut: null,
  strokesGained: null,
  suggestedMetrics: [],
  benchmarkDetails: null,
  performanceIndicators: [],
  shotsDistribution: null,
  status: SummaryStatus.UnInitialised,
  filters: {
    typeFilter: TypeFilter.Both,
    timeFilter: TimeFilter.LastFive,
    whereFilter: WhereFilter.Outdoor,
    customTimeRange: null,
  },
}

const { actions, reducer } = createSlice({
  name: 'summary',
  initialState,
  reducers: {
    updateStatus: (state, action: PayloadAction<StatusPayload>) => {
      const { status } = action.payload

      state.status = status
    },
    updateSummary: (state, action: PayloadAction<SummaryPayload>) => {
      return {
        ...state,
        ...action.payload,
        status: SummaryStatus.Valid,
      }
    },
    updateIO: (state, action: PayloadAction<IOPayload>) => {
      const { IOList, savedMetrics, suggestedMetrics } = action.payload

      state.IOList = IOList || []
      state.savedMetrics = savedMetrics || []
      state.suggestedMetrics = suggestedMetrics || []
    },
    updateFilters: (state, action: PayloadAction<Partial<Filters>>) => {
      const { payload } = action

      state.filters = { ...state.filters, ...payload }
    },
    reinitialiseSummary: () => {
      return initialState
    },
  },
})

export default reducer
export const {
  updateIO,
  updateStatus,
  updateFilters,
  updateSummary,
  reinitialiseSummary,
} = actions

// Selectors
const summarySelector = (state: RootState) => state.summary
export const summaryFiltersSelector = (state: RootState) =>
  state.summary.filters
export const summaryStrokesGainedSelector = (state: RootState) =>
  state.summary.strokesGained
const summaryBenchmarkDetailsSelector = (state: RootState) =>
  state.summary.benchmarkDetails
const summarySavedMetricSelector = (state: RootState) =>
  state.summary.savedMetrics
const summarySuggestedMetricSelector = (state: RootState) =>
  state.summary.suggestedMetrics
export const summaryIOListSelector = (state: RootState) => state.summary.IOList
export const summaryRoundsSelector = (state: RootState) => state.summary.rounds
export const summaryFocusAreasSelector = (state: RootState) =>
  state.summary.focusAreas
const summaryCategoriesSelector = (state: RootState) => state.summary.categories
const summaryPerformanceIndicatorsSelector = (state: RootState) =>
  state.summary.performanceIndicators

export const summaryStatusSelector = createSelector(
  summarySelector,
  summary => summary.status
)

export const summaryProgressSelector = createSelector(
  summarySelector,
  summary => summary.progressDonut
)

export const summaryShotsDistribution = createSelector(
  summarySelector,
  summary => summary.shotsDistribution
)

export const summaryShotsDistributionByMetric = (metricId: MetricId) =>
  createSelector(summaryShotsDistribution, shotsDistribution => {
    if (shotsDistribution) {
      switch (metricId) {
        case MetricId.Drives:
        case MetricId.DrivesPar4:
        case MetricId.DrivesPar5:
          return shotsDistribution!.breakdown.offTheTee
        case MetricId.Approach:
          return shotsDistribution!.breakdown.approach
        case MetricId.Approach101To150:
          return shotsDistribution!.breakdown.approach100to150
        case MetricId.Approach151To200:
          return shotsDistribution!.breakdown.approach150to200
        case MetricId.Approach201To250:
          return shotsDistribution!.breakdown.approach200to250
        case MetricId.ApproachOver250:
          return shotsDistribution!.breakdown.approachOver250
        case MetricId.Short:
          return shotsDistribution!.breakdown.shortGame
        case MetricId.ShortUnder20:
          return shotsDistribution!.breakdown.shortLessThan20
        case MetricId.Short21To60:
          return shotsDistribution!.breakdown.short20to60
        case MetricId.Short61To100:
          return shotsDistribution!.breakdown.short60to100
        case MetricId.ShortBunkers:
          return shotsDistribution!.breakdown.shortSand0to100
        case MetricId.Putt:
          return shotsDistribution!.breakdown.putting
        case MetricId.PuttUnder6:
          return shotsDistribution!.breakdown.puttingLessThan7
        case MetricId.Putt7To21:
          return shotsDistribution!.breakdown.putting7to21
        case MetricId.PuttOver21:
          return shotsDistribution!.breakdown.puttingOver21
      }
    }
  })

export const textualSummarySelector = createSelector(
  summarySelector,
  summaryRoundsSelector,
  summaryStrokesGainedSelector,
  (summary, rounds, strokesGained) => ({
    date: rounds[0]?.datePlayed,
    benchmarkDetails: summary.benchmarkDetails,
    progress: strokesGained?.total.progress as number,
    strokesGained: strokesGained?.total.overall as number | null,
  })
)

export const extractDonutInfo = (
  benchmarkDetails: BenchmarkDetails | null,
  strokesGained: RootStrokesGained | null
) => ({
  strokesGained: strokesGained?.total.overall as number,
  benchmarkDetails: benchmarkDetails as BenchmarkDetails,
})
export const donutInfoSelector = createSelector(
  summaryBenchmarkDetailsSelector,
  summaryStrokesGainedSelector,
  extractDonutInfo
)

export const extractComposedStrokesData = (
  rounds: SummaryRound[],
  strokesGained: RootStrokesGained | null
) => {
  const putt: SummarySingleItem = {
    data: [],
    sgTotal: strokesGained?.putt.overall,
    progress: strokesGained?.putt.progress,
    benchmark: strokesGained?.putt.benchmark as number,
  }
  const all: SummarySingleItem = {
    data: [],
    sgTotal: strokesGained?.total.overall,
    progress: strokesGained?.total.progress,
    benchmark: strokesGained?.total.benchmark as number,
  }
  const short: SummarySingleItem = {
    data: [],
    sgTotal: strokesGained?.short.overall,
    progress: strokesGained?.short.progress,
    benchmark: strokesGained?.short.benchmark as number,
  }
  const drives: SummarySingleItem = {
    data: [],
    sgTotal: strokesGained?.drives.overall,
    progress: strokesGained?.drives.progress,
    benchmark: strokesGained?.drives.benchmark as number,
  }
  const approach: SummarySingleItem = {
    data: [],
    sgTotal: strokesGained?.approach.overall,
    progress: strokesGained?.approach.progress,
    benchmark: strokesGained?.approach.benchmark as number,
  }

  rounds.forEach(round => {
    const roundTitle = getRoundTitle(round)
    const dateValue = new Date(round.datePlayed).getTime()

    putt.data.push({
      roundTitle,
      uuid: round.uuid,
      datePlayed: dateValue,
      value: round!.summary!.strokesGained.putt.overall,
      average: round!.summary!.strokesGained.putt.average,
      benchmark: round!.summary!.strokesGained.putt.benchmark,
      rollingAverage: round!.summary!.strokesGained.putt.rollingAverage,
    })
    all.data.push({
      roundTitle,
      uuid: round.uuid,
      datePlayed: dateValue,
      value: round!.summary!.strokesGained.total.overall,
      average: round!.summary!.strokesGained.total.average,
      benchmark: round!.summary!.strokesGained.total.benchmark,
      rollingAverage: round!.summary!.strokesGained.total.rollingAverage,
    })
    short.data.push({
      roundTitle,
      uuid: round.uuid,
      datePlayed: dateValue,
      value: round!.summary!.strokesGained.short.overall,
      average: round!.summary!.strokesGained.short.average,
      benchmark: round!.summary!.strokesGained.short.benchmark,
      rollingAverage: round!.summary!.strokesGained.short.rollingAverage,
    })
    drives.data.push({
      roundTitle,
      uuid: round.uuid,
      datePlayed: dateValue,
      value: round!.summary!.strokesGained.drives.overall,
      average: round!.summary!.strokesGained.drives.average,
      benchmark: round!.summary!.strokesGained.drives.benchmark,
      rollingAverage: round!.summary!.strokesGained.drives.rollingAverage,
    })
    approach.data.push({
      roundTitle,
      uuid: round.uuid,
      datePlayed: dateValue,
      value: round!.summary!.strokesGained.approach.overall,
      average: round!.summary!.strokesGained.approach.average,
      benchmark: round!.summary!.strokesGained.approach.benchmark,
      rollingAverage: round!.summary!.strokesGained.approach.rollingAverage,
    })
  })

  return { all, putt, short, drives, approach }
}

export const summaryComposedDataSelector = createSelector(
  summaryRoundsSelector,
  summaryStrokesGainedSelector,
  extractComposedStrokesData
)

export const summarySubCategoryData = (category: CategoryType) =>
  createSelector(
    summaryRoundsSelector,
    summaryCategoriesSelector,
    (rounds, categories) => {
      const subCategoryData: SubcategoryData = {}

      // Don't calculate subcategory data for CategoryType.All
      if (category === CategoryType.All) {
        return subCategoryData
      }

      categories.forEach(
        ({ metricId, benchmark, progress, strokesGained, type }) => {
          if (type === category) {
            subCategoryData[metricId as MetricId] = {
              data: [],
              progress: progress,
              benchmark: benchmark,
              sgTotal: strokesGained,
            }
          }
        }
      )

      rounds.forEach(round => {
        const roundTitle = getRoundTitle(round)
        const dateValue = new Date(round.datePlayed).getTime()

        if (!round.summary) {
          return
        }

        round.summary.categories.forEach(
          ({ average, metricId, benchmark, strokesGained, rollingAverage }) => {
            const data = {
              average,
              benchmark,
              roundTitle,
              uuid: round.uuid,
              value: strokesGained,
              datePlayed: dateValue,
              rollingAverage,
            }

            if (!subCategoryData[metricId as MetricId]) {
              return
            }

            subCategoryData[metricId as MetricId]!.data.push(data)
          }
        )
      })

      return subCategoryData
    }
  )

export const summaryRadarDataSelector = createSelector(
  summaryStrokesGainedSelector,
  extractRadarData
)

export const summaryScoreSelector = createSelector(
  summaryRoundsSelector,
  extractScoreInfo
)

export const savedIOSelector = createSelector(
  summaryIOListSelector,
  summarySavedMetricSelector,
  (IOList, savedMetrics) =>
    savedMetrics.map(
      saved => IOList.find(io => io.metricId === saved.metricId) || saved
    )
)

export const strengthOpportunitySelector = createSelector(
  summaryFocusAreasSelector,
  summaryCategoriesSelector,
  summaryStrokesGainedSelector,
  (focusAreas, categories, strokesGained) => {
    const opportunityId = focusAreas[0]?.metricId
    const strengthId = focusAreas[focusAreas.length - 1]?.metricId

    const opportunityStrokes = getStrokesForOpportunity(
      opportunityId,
      categories,
      strokesGained
    )

    const strengthStrokes = getStrokesForOpportunity(
      strengthId,
      categories,
      strokesGained
    )

    return {
      strength: { metricId: strengthId, strokesGained: strengthStrokes },
      opportunity: {
        metricId: opportunityId,
        strokesGained: opportunityStrokes,
      },
    }
  }
)

export const improvementPotentialSelector = createSelector(
  summarySavedMetricSelector,
  summaryCategoriesSelector,
  summaryStrokesGainedSelector,
  extractIOPotential
)

export const suggestedIOSelector = createSelector(
  summaryCategoriesSelector,
  summaryStrokesGainedSelector,
  summarySuggestedMetricSelector,
  (categories, strokesGained, suggestedMetrics) =>
    suggestedMetrics?.map(({ metricId }) => {
      const strokesGainedValue = getStrokesForOpportunity(
        metricId,
        categories,
        strokesGained
      ) as number

      return { metricId, strokesGained: strokesGainedValue }
    })
)

export const opportunitiesGraphData = createSelector(
  userSelector,
  playerSelector,
  summaryRoundsSelector,
  summaryCategoriesSelector,
  summarySavedMetricSelector,
  summaryStrokesGainedSelector,
  (user, { uuid }, rounds, categories, savedMetrics, strokesGained) => {
    const graphData: OpportunityItem[] = []
    const isPlayer = user.metadata?.userType === UserType.Player

    if (!savedMetrics) {
      return
    }

    const sortedRounds = [...rounds].sort(
      (a, b) =>
        new Date(a.datePlayed).getTime() - new Date(b.datePlayed).getTime()
    )

    savedMetrics.forEach(({ metricId }) => {
      if (metricId === MetricId.Drives) {
        const path = generateCategoryPath(
          CategoryRoutes.Drives,
          isPlayer ? undefined : uuid
        )

        const payload: OpportunityItem = {
          path,
          metricId,
          data: [],
          sgTotal: strokesGained?.drives.overall,
          progress: strokesGained?.drives.progress,
          benchmark: strokesGained?.drives.benchmark as number,
        }

        sortedRounds.forEach(round => {
          const roundTitle = getRoundTitle(round)
          const dateValue = new Date(round.datePlayed).getTime()

          payload.data.push({
            roundTitle,
            uuid: round.uuid,
            datePlayed: dateValue,
            value: round!.summary!.strokesGained.drives.overall,
            average: round!.summary!.strokesGained.drives.average,
            benchmark: round!.summary!.strokesGained.drives.benchmark,
            rollingAverage: round!.summary!.strokesGained.drives.rollingAverage,
          })
        })

        graphData.push(payload)
      } else {
        const rootCategory = categories.find(item => metricId === item.metricId)
        const path = generateCategoryPath(
          `/${rootCategory?.type}/${metricId}`,
          isPlayer ? undefined : uuid
        )

        const payload: OpportunityItem = {
          path,
          metricId,
          data: [],
          progress: rootCategory?.progress,
          sgTotal: rootCategory?.strokesGained,
          benchmark: rootCategory?.benchmark as number,
        }

        sortedRounds.forEach(round => {
          const roundTitle = getRoundTitle(round)
          const dateValue = new Date(round.datePlayed).getTime()
          const category = round.summary?.categories.find(
            item => metricId === item.metricId
          )

          payload.data.push({
            roundTitle,
            uuid: round.uuid,
            datePlayed: dateValue,
            average: category?.average as number,
            value: category?.strokesGained || null,
            benchmark: category?.benchmark as number,
            rollingAverage: category?.rollingAverage,
          })
        })

        graphData.push(payload)
      }
    })

    return graphData
  }
)

const getRootCategoryValue = (round: SummaryRound, metricId: string) => {
  const summary = round.summary

  switch (metricId) {
    case MetricId.Drives:
      return summary?.strokesGained.drives
    case MetricId.Approach:
      return summary?.strokesGained.approach
    case MetricId.Short:
      return summary?.strokesGained.short
    case MetricId.Putt:
      return summary?.strokesGained.putt
  }
}

export const strengthGraphDataSelector = (strengths: FocusAreaIO[]) =>
  createSelector(
    userSelector,
    playerSelector,
    summaryRoundsSelector,
    summaryCategoriesSelector,
    (user, { uuid }, rounds, categories) => {
      const isPlayer = user.metadata?.userType === UserType.Player
      return strengths.map(strength => {
        const roundsFocusArea: Strength[] = []
        let path = ''
        if (strength.metricId === MetricId.Drives) {
          path = generateCategoryPath(
            CategoryRoutes.Drives,
            isPlayer ? undefined : uuid
          )
        } else {
          const rootCategory = categories.find(
            item => strength.metricId === item.metricId
          )
          path = generateCategoryPath(
            `/${rootCategory?.type}/${strength.metricId}`,
            isPlayer ? undefined : uuid
          )
        }

        rounds.forEach(round => {
          const rootCategories = [
            MetricId.Drives,
            MetricId.Approach,
            MetricId.Short,
            MetricId.Putt,
          ]
          let category
          let value
          if (rootCategories.includes(strength.metricId)) {
            category = getRootCategoryValue(round, strength.metricId)
            value = category?.overall ?? 0
          } else {
            category = round.summary?.categories.find(
              item => strength.metricId === item.metricId
            )
            value = category?.strokesGained ?? 0
          }

          if (category) {
            roundsFocusArea.push({
              roundTitle: getRoundTitle(round),
              metricId: strength.metricId,
              metricName: 'test',
              path: path,
              datePlayed: new Date(round.datePlayed).getTime(),
              difference: value,
              benchmark: category?.benchmark,
              average: category?.average,
              rollingAverage: category?.rollingAverage ?? 0,
            })
          }
        })
        return roundsFocusArea
      })
    }
  )
export const metricPerformanceIndicatorsSelector = (
  metricId: MetricId,
  isSubcategory: boolean
) =>
  createSelector(summaryPerformanceIndicatorsSelector, performanceIndicators =>
    performanceIndicators?.filter(({ category, subcategories }) => {
      if (isSubcategory) {
        return subcategories.includes(metricId)
      }

      if (category instanceof Array) {
        return category.includes(metricId)
      }
      return category === metricId
    })
  )

// Action Creators
export const getSummary =
  (category: CategoryType = CategoryType.All): AppThunk =>
  async (dispatch, getState) => {
    dispatch(updateStatus({ status: SummaryStatus.Loading }))
    const state = getState()
    const { isPlayer, playerUuid } = getUserInfo(state)
    const isPlayerTrialing = userIsTrialingSelector(state)
    const isPlayerPremium = hasActiveSubscription()(state)

    const shouldFetchIOs = isPlayerPremium || isPlayerTrialing

    const {
      timeFilter: time,
      typeFilter: type,
      whereFilter: where,
      customTimeRange,
    } = summaryFiltersSelector(state)

    const summaryEndpoint = isPlayer
      ? 'summary'
      : `/overview/player/${playerUuid}/summary`
    const ioEndpoint = isPlayer ? 'io' : `/overview/player/${playerUuid}/io`

    const summaryParams: SummaryParams = {
      time,
      type,
      where,
      category,
      excludeForecast: true,
    }
    if (time === TimeFilter.Custom) {
      summaryParams.customTimeRange = customTimeRange
    }

    try {
      const requests = [
        api.get(summaryEndpoint, {
          params: summaryParams,
        }),
      ]
      // We only request the IO if the user has subscription or if Coach
      // otherwise the 403 response causes logout
      // TODO: we need to differentiate Lite & Premium subscriptions
      if (shouldFetchIOs) {
        requests.push(api.get(ioEndpoint))
      }

      const response = await Promise.all(requests)

      const { totalSummary, totalTrendSummary, ...restSummary } =
        getSinglePayloadFromResponse(response[0])

      dispatch(updateSummary({ ...totalSummary, ...restSummary }))

      if (shouldFetchIOs) {
        const { list: IOList, ...restIO } = getSinglePayloadFromResponse(
          response[1]
        )
        dispatch(updateIO({ IOList, ...restIO }))
      }
    } catch (error: any) {
      const errorMessage = error.response?.data.message
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      } else if (errorMessage === SummaryErrors.NoRoundsEntered) {
        dispatch(updateStatus({ status: SummaryStatus.NoRoundsError }))
      } else if (errorMessage === SummaryErrors.NoRoundsForFilters) {
        dispatch(updateStatus({ status: SummaryStatus.NoFilterRounds }))
      } else {
        dispatch(updateStatus({ status: SummaryStatus.Error }))
      }
    }
  }

export const getSummaryWithBenchmark =
  (category: CategoryType = CategoryType.All): AppThunk =>
  async (dispatch, getState) => {
    dispatch(updateStatus({ status: SummaryStatus.Loading }))
    const state = getState()
    const { isPlayer, playerUuid } = getUserInfo(state)
    const isPlayerTrialing = userIsTrialingSelector(state)
    const isPlayerPremium = hasActiveSubscription()(state)

    const shouldFetchIOs = isPlayerPremium || isPlayerTrialing

    const {
      timeFilter: time,
      typeFilter: type,
      customTimeRange,
    } = summaryFiltersSelector(state)

    const summaryEndpoint = isPlayer
      ? 'summary'
      : `/overview/player/${playerUuid}/summary`
    const ioEndpoint = isPlayer ? 'io' : `/overview/player/${playerUuid}/io`

    const summaryParams: SummaryParams = {
      time,
      type,
      category,
    }
    if (time === TimeFilter.Custom) {
      summaryParams.customTimeRange = customTimeRange
    }

    try {
      const responseIO = await api.get(ioEndpoint)

      const {
        benchmark,
        list: IOList,
        ...restIO
      } = getSinglePayloadFromResponse(responseIO)

      const responseSummary = await api.get(`${summaryEndpoint}/${benchmark}`, {
        params: summaryParams,
      })

      const { totalSummary, totalTrendSummary, ...restSummary } =
        getSinglePayloadFromResponse(responseSummary)

      dispatch(updateSummary({ ...totalSummary, ...restSummary }))

      if (shouldFetchIOs) {
        dispatch(updateIO({ IOList, ...restIO }))
      }
    } catch (error: any) {
      const errorMessage = error.response?.data.message
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      } else if (errorMessage === SummaryErrors.NoRoundsEntered) {
        dispatch(updateStatus({ status: SummaryStatus.NoRoundsError }))
      } else if (errorMessage === SummaryErrors.NoRoundsForFilters) {
        dispatch(updateStatus({ status: SummaryStatus.NoFilterRounds }))
      } else {
        dispatch(updateStatus({ status: SummaryStatus.Error }))
      }
    }
  }

export const saveIO =
  (metricId: MetricId): AppThunk =>
  async (dispatch, getState) => {
    try {
      // Only Premium users can save IOs
      // we don't want to put an IO because the 403 response causes logout
      if (!hasActiveSubscription()(getState())) {
        throw new Error(FeatureGateErrors.PremiumFeatureError)
      }
      const state = getState()
      const { isPlayer, playerUuid } = getUserInfo(state)
      const ioEndpoint = isPlayer
        ? 'io/save'
        : `/overview/player/${playerUuid}/io/save`
      const response = await api.put(ioEndpoint, { metricId })
      const { list: IOList, ...restIO } = getSinglePayloadFromResponse(response)

      dispatch(updateIO({ IOList, ...restIO }))
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      } else {
        throw new Error('Could not save IO')
      }
    }
  }

export const deleteIO =
  (savedMetricId: MetricId): AppThunk =>
  async (dispatch, getState) => {
    // Only Premium users can delete IOs
    // we don't want to put an IO because the 403 response causes logout
    if (!hasActiveSubscription()(getState())) {
      throw new Error(FeatureGateErrors.PremiumFeatureError)
    }

    try {
      const response = await api.put('io/delete', { savedMetricId })
      const { list: IOList, ...restIO } = getSinglePayloadFromResponse(response)

      dispatch(updateIO({ IOList, ...restIO }))
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      } else {
        throw new Error('Could not delete IO')
      }
    }
  }

export const swapIO =
  (savedMetricId: MetricId, suggestedMetricId: MetricId): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()
    const { isPlayer, playerUuid } = getUserInfo(state)
    try {
      const ioEndpoint = isPlayer
        ? 'io/swap'
        : `/overview/player/${playerUuid}/io/swap`
      const response = await api.put(ioEndpoint, {
        savedMetricId,
        suggestedMetricId,
      })
      const { list: IOList, ...restIO } = getSinglePayloadFromResponse(response)

      dispatch(updateIO({ IOList, ...restIO }))
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      } else {
        throw new Error('Could not swap IO')
      }
    }
  }

export const dismissSuggestedIO =
  (suggestedMetricId: MetricId): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()
    const { isPlayer, playerUuid } = getUserInfo(state)
    try {
      const ioEndpoint = isPlayer
        ? 'io/dismiss'
        : `/overview/player/${playerUuid}/io/dismiss`
      const response = await api.put(ioEndpoint, {
        suggestedMetricId,
      })
      const { list: IOList, ...restIO } = getSinglePayloadFromResponse(response)

      dispatch(updateIO({ IOList, ...restIO }))
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
    }
  }

export const resetSummary = (): AppThunk => async dispatch => {
  try {
    dispatch(reinitialiseSummary())
  } catch (error: any) {
    if (isForbiddenOrUnauthorised(error)) {
      authRequestFail(dispatch)
    }
  }
}
