import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Field, Form, Formik, FormikConfig } from 'formik'
import { dateTransform } from 'src/utils/helpers'
import Typography from '@material-ui/core/Typography'
import { Filters, getSummary, updateFilters } from 'src/store/summarySlice'
import { breakpoints, DateFormat, Languages } from 'src/utils/constants'
import { FormDatePicker } from 'src/components/common'
import * as Yup from 'yup'
import styled, { css } from 'styled-components'
import { useDispatch } from 'react-redux'
import CalendarIcon from 'src/assets/svgComponents/CalendarIcon'
import LoaderButton from './LoaderButton'
import moment from 'moment'

const I18N_KEY = 'CustomTimeFilters'

interface CustomDatePickerProps {
  filters: Filters
  isDesktop?: boolean
  isDarkTheme?: boolean
  autoUpdate?: boolean
}

export interface CustomTimeRange {
  startDate: Date
  endDate: Date
}

const HeroText = styled(Typography)<{ $dark: boolean }>(
  ({ theme, $dark }) => css`
    color: ${$dark
      ? theme.palette.background.paper
      : theme.palette.background.black};

    ${theme.breakpoints.down(breakpoints.mobile)} {
      text-align: center;
    }

    ${theme.breakpoints.down('xs')} {
      font-size: ${theme.typography.pxToRem(12)};
      margin: 0;
    }
  `
)

const FieldContainer = styled.div<{ $dark: boolean }>(
  ({ theme, $dark }) => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .MuiFormControl-root {
      width: auto;
      margin-top: 0;
    }

    .MuiOutlinedInput-input {
      width: auto;
    }

    .MuiOutlinedInput-input {
      width: 6rem;
      padding: 0 0.5rem;
      color: ${$dark
        ? theme.palette.background.paper
        : theme.palette.background.black};
      text-align: right;

      ${theme.breakpoints.down('xs')} {
        width: 10.5rem;
        font-size: ${theme.typography.pxToRem(12)};
      }
    }

    ${theme.breakpoints.down('sm')} {
      display: flex;
      align-items: center;
      width: 90vw;
    }
  `
)

const ButtonContainer = styled.div(
  ({ theme }) => css`
    margin-left: 1rem;
    ${theme.breakpoints.down('xs')} {
      margin-left: 0.25rem;
    }

    .MuiButtonBase-root {
      ${theme.breakpoints.down('xs')} {
        width: 4rem;
        font-size: ${theme.typography.pxToRem(12)};
        padding: ${theme.spacing(0.5, 0)};
      }
    }

    .MuiCircularProgress-root {
      color: ${theme.palette.primary.main};
    }
  `
)

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  filters,
  isDesktop,
  isDarkTheme = false,
  autoUpdate = false,
}) => {
  const dispatch = useDispatch()
  const { i18n } = useTranslation()
  const { t } = useTranslation()

  let customTimeFilters: CustomTimeRange = {
    startDate: new Date(),
    endDate: new Date(),
  }
  if (filters.customTimeRange != null) {
    customTimeFilters = JSON.parse(filters.customTimeRange)
  }

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        startDate: Yup.date()
          .transform(dateTransform)
          .required(
            t(
              `${I18N_KEY}.startDateRequiredErrorMessage`,
              'Please enter a valid start date'
            )
          ),
        endDate: Yup.date()
          .transform(dateTransform)
          .required(
            t(
              `${I18N_KEY}.endDateRequiredErrorMessage`,
              'Please enter a valid end date'
            )
          ),
      }),
    [t]
  )

  const customFormikOptions: FormikConfig<CustomTimeRange> = {
    initialValues: {
      startDate: customTimeFilters?.startDate,
      endDate: customTimeFilters?.endDate,
    },
    validationSchema,
    onSubmit: (values: CustomTimeRange) => {
      dispatch(
        updateFilters({
          customTimeRange: JSON.stringify({
            startDate: moment(values.startDate).startOf('day').toDate(),
            endDate: moment(values.endDate).endOf('day').toDate(),
          }),
        })
      )
      dispatch(getSummary())
      return Promise.resolve()
    },
  }

  // Handle StartDate/EndDate onChange should only trigger if "autoUpdate" prop is true
  const handleStartDateChange = (value: string, setFieldValue: any) => {
    setFieldValue('startDate', value)
    dispatch(
      updateFilters({
        customTimeRange: JSON.stringify({
          startDate: moment(value).startOf('day').toDate(),
          endDate: customTimeFilters?.endDate,
        }),
      })
    )
    dispatch(getSummary())
  }

  // Handle StartDate/EndDate onChange should only trigger if "autoUpdate" prop is true
  const handleEndDateChange = (value: string, setFieldValue: any) => {
    setFieldValue('endDate', value)
    dispatch(
      updateFilters({
        customTimeRange: JSON.stringify({
          startDate: customTimeFilters?.startDate,
          endDate: moment(value).endOf('day').toDate(),
        }),
      })
    )
    dispatch(getSummary())
  }

  return (
    <FieldContainer $dark={isDarkTheme}>
      <Formik {...customFormikOptions}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <FieldContainer $dark={isDarkTheme}>
              <Trans i18nKey={`${I18N_KEY}.from`}>
                <HeroText $dark={isDarkTheme}>From:</HeroText>
              </Trans>
              <Field
                autoOk
                fullWidth
                helperText=""
                name="startDate"
                inputVariant="outlined"
                disabled={false}
                component={FormDatePicker}
                views={undefined}
                openTo={undefined}
                value={values.startDate}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: isDesktop ? <CalendarIcon /> : null,
                }}
                format={DateFormat[i18n.language as Languages].datePicker}
                onChange={
                  autoUpdate
                    ? (data: string) =>
                        handleStartDateChange(data, setFieldValue)
                    : null
                }
              />
              <Trans i18nKey={`${I18N_KEY}.to`}>
                <HeroText $dark={isDarkTheme}>To:</HeroText>
              </Trans>
              <Field
                autoOk
                fullWidth
                helperText=""
                name="endDate"
                inputVariant="outlined"
                disabled={false}
                component={FormDatePicker}
                views={undefined}
                openTo={undefined}
                value={values.endDate}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: isDesktop ? <CalendarIcon /> : null,
                }}
                format={DateFormat[i18n.language as Languages].datePicker}
                onChange={
                  autoUpdate
                    ? (data: string) => handleEndDateChange(data, setFieldValue)
                    : null
                }
              />
              {!autoUpdate && (
                <ButtonContainer>
                  <LoaderButton
                    fullWidth
                    type="submit"
                    color="primary"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    <Trans i18nKey={`${I18N_KEY}.apply`}>Apply</Trans>
                  </LoaderButton>
                </ButtonContainer>
              )}
            </FieldContainer>
          </Form>
        )}
      </Formik>
    </FieldContainer>
  )
}

export default CustomDatePicker
