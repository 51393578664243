import React from 'react'
import styled, { css } from 'styled-components'

import { Select, Typography } from '@material-ui/core'
import Circle from '@material-ui/icons/FiberManualRecord'

import Dash from 'src/assets/svgComponents/DashIcon'
import ScratchDash from 'src/assets/svgComponents/ScratchDash'
import Difference from 'src/assets/svgComponents/DifferenceIcon'

export const TooltipContainer = styled.div(
  ({ theme }) => css`
    width: 250px;
    border-radius: 6px;
    padding: ${theme.spacing(2, 0)};
    background-color: ${theme.palette.background.black};
  `
)

export const TooltipTitle = styled(Typography)(
  ({ theme }) => css`
    margin: 0;
    color: ${theme.palette.background.paper};
  `
)

export const TooltipText = styled(props => (
  <Typography component="p" variant="h6" {...props} />
))<{ $isValue?: boolean }>(
  ({ theme, $isValue = false }) => css`
    display: flex;
    align-items: center;
    font-weight: ${$isValue ? 500 : 'normal'};
    color: ${theme.palette.background.paper};
  `
)

export const DifferenceIcon = styled(Difference)(
  ({ theme }) => css`
    margin-right: ${theme.spacing(1)}px;
  `
)

export const DashIcon = styled(Dash)<{ $isScratch?: boolean }>(
  ({ theme, $isScratch = false }) => css`
    margin-right: ${theme.spacing(1)}px;

    path {
      stroke: ${$isScratch
        ? theme.palette.warning.main
        : theme.palette.primary.light};
    }
  `
)

export const CircleIcon = styled(Circle)<{ $valueIsNull?: boolean }>(
  ({ theme, $valueIsNull = false }) => css`
    color: ${$valueIsNull
      ? theme.palette.background.light
      : theme.palette.primary.light};
    margin-right: ${theme.spacing(0.75)}px;
    font-size: ${theme.typography.pxToRem(14)};
  `
)

export const ScratchIcon = styled(ScratchDash)(
  ({ theme }) => css`
    margin-right: ${theme.spacing(1)}px;
    color: ${theme.palette.warning.main};
  `
)

export const DifferenceText = styled(({ ...props }) => (
  <Typography component="p" variant="h6" {...props} />
))<{ $isGreater: boolean }>(
  ({ theme, $isGreater }) => css`
    font-weight: 500;
    color: ${$isGreater
      ? theme.palette.success.main
      : theme.palette.error.main};
  `
)

export const AxisLabel = styled.text(
  ({ theme }) => css`
    font-weight: 500;
    text-transform: uppercase;
    fill: ${theme.palette.text.secondary};
    font-size: ${theme.typography.pxToRem(12)};
    line-height: ${theme.typography.pxToRem(16)};
    letter-spacing: ${theme.typography.pxToRem(0.3)};

    ${theme.breakpoints.down('xs')} {
      font-size: ${theme.typography.pxToRem(8)};
      line-height: ${theme.typography.pxToRem(12)};
    }
  `
)

export const GraphSelect = styled(Select)(
  ({ theme }) => css`
    font-weight: 500;
    margin: ${theme.spacing(0, 0.75)};
    color: ${theme.palette.background.black};

    :hover {
      color: ${theme.palette.primary.main};
    }

    .MuiSelect-select.MuiSelect-select {
      padding-top: 0;
      padding-bottom: 0;
    }

    &.MuiInputBase-root.Mui-disabled {
      color: ${theme.palette.background.grey};
      background-color: ${theme.palette.background.black};
    }

    .MuiSelect-icon.Mui-disabled {
      color: ${theme.palette.background.grey};
    }

    .MuiInput-underline::before {
      border-bottom-color: ${theme.palette.background.black};
    }
  `
)
